import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpRequest,
	HttpHandler,
	HttpSentEvent,
	HttpHeaderResponse,
	HttpProgressEvent,
	HttpResponse,
	HttpUserEvent,
	HttpErrorResponse,
	HttpHeaders
} from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenStorage } from '../../util/token.storage';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { Util } from './../../util/util';

@Injectable({
	providedIn: 'root'
})
export class InterceptService implements HttpInterceptor {
	constructor(
		private token: TokenStorage,
		private router: Router,
		private util: Util
	) {}

	addTokenRequest(request) {
		let req;
		if (localStorage.getItem('AuthToken') != null) {
			req = request.clone({
				headers: new HttpHeaders({
					Authorization:
						'Bearer ' + localStorage.getItem('AuthToken') + ''
				})
			});
			return req;
		}

		return request;
	}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<
		| HttpSentEvent
		| HttpHeaderResponse
		| HttpProgressEvent
		| HttpResponse<any>
		| HttpUserEvent<any>
	> {
		let authReq = this.addTokenRequest(req);
		let that = this;
		console.log(authReq);
		return next.handle(authReq).pipe(
			tap(
				event => {
					//console.log(event);
				},
				error => {
					if (error.status == 401) {
						this.util.presentLoading('Espere un momento...');
						firebase
							.auth()
							.currentUser.getIdToken(/* forceRefresh */ true)
							.then(function (idToken) {
								that.token.saveToken(idToken).then(R => {
									that.util.dismissLoading();
									that.util.closeModal();
									that.util.redirectLoginSucces();
									//location.reload();
								});
							})
							.catch(function (error) {
								console.log(error);
							});
					}

					console.log(error);
				}
			)
		);
	}
}
