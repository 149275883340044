import { API } from './../../util/constants';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TermsService {
	constructor(private http: HttpClient) {}

	getTerms(): Observable<any> {
		return this.http.get<any>(API + '/terms');
	}
}
