import { File } from '@ionic-native/file/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { Router } from '@angular/router';
import { TokenStorage } from './token.storage';
import { environment } from './../../environments/environment';
import { UserService } from './../services/user/user.service';
import { Injectable } from '@angular/core';
import { LoadingController, ToastController, MenuController, Platform, ModalController } from '@ionic/angular';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { Camera } from '@ionic-native/camera/ngx';
@Injectable({
    providedIn: 'root'
})
export class Util {

    constructor(
        public loadingController: LoadingController,
        public tc: ToastController,
        public menuCtrl: MenuController,
        public userService:UserService,
        public token:TokenStorage,
        public router:Router,
        public camera: Camera,
        public platform: Platform,
        public file: File,
        public filePath: FilePath,
        public modalCtrl: ModalController
    

    ) { 
        if (!firebase.apps.length) {
            firebase.initializeApp(environment.firebaseConfig);
        }
    }


    /**
     * @description Metodo para mostrar alertas
     * @param mensaje Mensaje a mostrar en el alert
     * @param tipo Tipos de alert: "alert" fondo rojo en el alert, "succes"  fondo azul primario en el aler
     */
    async presentToast(mensaje, tipo) {
        switch (tipo) {
            case "alert":
                const t1 = await this.tc.create({
                    message: mensaje,
                    showCloseButton: false,
                    position: 'bottom',
                    duration: 3000,
                    //closeButtonText: 'Cerrar',
                    id: "alerttoast"
                });
                t1.present();
                break;
            case "succes":
                const t2 = await this.tc.create({
                    message: mensaje,
                    showCloseButton: false,
                    position: 'bottom',
                    duration: 4000,
                    //closeButtonText: 'Cerrar',
                    id: "succestoast"
                });
                t2.present();
                break;
        }

    }
    /**
     * Crear nombre para la imagen tomada
     */
    static createFileName() {
        var d = new Date(),
            n = d.getTime(),
            newFileName = n + ".jpg";
        return newFileName;
    }

    /**
     * @description Calcula la edad segun fecha dada
     * @param dateString Fecha para calcular la edad
     */
    getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    /**
     * @description Muestra spinner loading para bloquear actividad con el usuario
     * 
     */
    async presentLoading(message="Espere...") {        
        const loading = await this.loadingController.create({
            spinner: 'bubbles',
            message: message,
            translucent: true
        });
        return await loading.present();
    }
    /**
     * @description Cierra el spinner de loading 
     */
    dismissLoading() {
        var that = this;
        var a = setInterval(function () {
            that.loadingController.dismiss();
            clearInterval(a);
        }, 500)

    }

    /**
     * @description Abrir el menu lateral!!
     */

    openMenu(){     
        //console.log("a"); 
        this.menuCtrl.enable(true);
        //console.log(this.menuCtrl.isEnabled());  
       // this.menuCtrl.open();
       
        this.menuCtrl.toggle();
    }
    /**
     * @description Cerrar el menu lateral!!
     */
    closeMenu() {
        this.menuCtrl.close();
    }

   /**
   * @description Metodo llamada luego de iniciar sesion sin importar el metodo(facebook, google, pass/Email)
   */
  redirectLoginSucces() {

    var that = this;
      this.userService.getDataUser().subscribe(
        res => {
          this.dismissLoading();
          localStorage.setItem("userApi", JSON.stringify(res.user));
          if (res.user.status_account == 1) {                    
            //si ya completo su registro envio al home
            
             this.router.navigateByUrl('/solicitudes');
                      
            
          } else if(res.user.status_account == 2){
            this.router.navigateByUrl('/home');

          } else if(res.user.status_account == 3){
            this.router.navigateByUrl('/home');
          }else if(res.user.status_account == 4){
            this.router.navigateByUrl('/home');
          }else{
            //enviar al step de registro correspondiente
            switch (res.user.step_register) {
              case 1:
                //that.router.navigateByUrl('/singup');   
                this.router.navigate(['/singup', { step: 0 }]);
                break;
              case 2:
                this.router.navigate(['/singup', { step: 1 }]);
                break;
              case 3:
                this.router.navigate(['/singup', { step: 2 }]);
                break;
              default:
                this.router.navigate(['/singup', { step: 0 }]);
                break;
            }//cierre switch                
          }//cierre else
        },
        err => {
          firebase.auth().currentUser.getIdToken(true).then(function (idToken) {
            //guardar jwt
            that.token.saveToken(idToken).then((R)=>{
              that.redirectLoginSucces();  
            });
          }).catch(function (error) {
            console.log(error);
          });
        }
      );//cierre service user
    }
     






    /**
     * @description Metodo para tomar fotos usando carama o galeria 
     * @param sourceType Saber si la forma de capturar la foto es mediante la camara o la galeria
     * @returns retorna una promesa y como data recibe la imagen en base 64 (exitoso) o recibe "" (caso fallido)
     */
    public takePicture(sourceType):Promise<any> {
        return new Promise(Resolve=>{
            // Create options for the Camera Dialog
        var options = {
            quality: 50,
            sourceType: sourceType,
            saveToPhotoAlbum: false,
            correctOrientation: true
          };
          // Get the data of an image
          this.camera.getPicture(options).then((imagePath) => {
            // Special handling for Android library
            if (this.platform.is('android') && sourceType === this.camera.PictureSourceType.PHOTOLIBRARY) {
              this.filePath.resolveNativePath(imagePath)
                .then(filePath => {
                  let correctPath = filePath.substr(0, filePath.lastIndexOf('/') + 1);
                  let currentName = imagePath.substring(imagePath.lastIndexOf('/') + 1, imagePath.lastIndexOf('?'));
                  this.convertBase64(correctPath, currentName).then((R)=>{
                      Resolve(R);
                  });
                });
            } else {
              var currentName = imagePath.substr(imagePath.lastIndexOf('/') + 1);
              var correctPath = imagePath.substr(0, imagePath.lastIndexOf('/') + 1);
              this.convertBase64(correctPath, currentName).then((R)=>{
                Resolve(R);
            });
            }
          }, (err) => {
            this.presentToast("Cargue las imagenes desde la aplicación móvil", "alert");
            Resolve("");
          });


        })
        
    }



    
    /**
     * @description Convertir archivo capturado en base64 para enviar al back
     * @param namePath ruta
     * @param namefile nombre
     * @returns retorna el file en base 64 o "" en caso de no haber logrado convertir el archivo
     */
    public convertBase64(namePath, namefile):Promise<any> {
        return new Promise(Resolve=>{
            
        this.file.readAsDataURL(namePath, namefile).then(data => {
            Resolve(data);               
        }, error => {
          this.presentToast("Error al guardar el archivo.", "alert");      
          Resolve("");               
        }).catch(error => {
          // not triggered
          Resolve("");               
        })    
        
        })
        
      }


      /**
       * @description metodo para descrifrar la extencion del archivo en el url
       * @param url Url para saber la extension del archivo
       */
      ext(url) {
        return (url = url.substr(1 + url.lastIndexOf("/")).split('?')[0]).split('#')[0].substr(url.lastIndexOf("."))
      }


      closeModal(){
 
            this.modalCtrl.dismiss();
  
      }
}   