import { Util } from './../../../shared/util/util';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PolicyService } from './../../../shared/services/policy/policy.service';
import { Component, OnInit , Input} from '@angular/core';
import { ModalController, ActionSheetController, Platform} from '@ionic/angular';
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { Chooser } from '@ionic-native/chooser/ngx';
import { OtherService } from './../../../shared/services/other/other.service';


declare var cordova: any;
@Component({
  selector: 'app-edit-policy',
  templateUrl: './edit-policy.page.html',
  styleUrls: ['./edit-policy.page.scss'],
  providers: [PolicyService]
})
export class EditPolicyPage implements OnInit {
  policyform: FormGroup;
  insurances: any = [];
  countries:any = [];
  pass:boolean = false;
  filesred=[];
  filespoliza=[];
  filescarnet=[];
  @Input() type: any;
  body:any;

  constructor(
    private modalCtrl: ModalController,
    private policyService: PolicyService,
    private formBuilder: FormBuilder,
    private actionSheetCtrl: ActionSheetController,
    private camera: Camera,
    private platform: Platform,
    private file: File,
    private filePath: FilePath,
    private chooser: Chooser,
    public util:Util,
    private otherService: OtherService,
  ) {
    this.policyform = this.formBuilder.group({
      aseguradora: ['', Validators.required],
      nropolicy: ['', Validators.required],
      anual_amount: [''],
      valor: [''],
      fren: [''],
      pais: [''],
      phone_broker: [''],
      email_broker: [''],
      certificate: [''],
      type: [''],
      file: [''],
      file2: [''],
      file3: [''],
      typecarnet: [''],
      filecarnet: [''],
      typered: [''],
      filered: [''],
      
    });

    
  }

  ngOnInit() {    
    this.util.presentLoading();
    if(this.type == 'edit'){
    this.policyService.getDataPolicy().subscribe(
      res => {
        this.policyform.get('aseguradora').setValue(res.policy.insurance_carrier);
        this.policyform.get('nropolicy').setValue(res.policy.policy_number);
        this.policyform.get('valor').setValue(res.policy.value);
        this.policyform.get('fren').setValue(res.policy.date_of_issue);
        this.policyform.get('pais').setValue(res.policy.country);
        this.policyform.get('phone_broker').setValue(res.policy.phone_broker);
        this.policyform.get('email_broker').setValue(res.policy.email_broker);
        this.policyform.get('certificate').setValue(res.policy.certificate);
        this.policyform.get('anual_amount').setValue(res.policy.anual_amount);
        this.otherService.getInsurance().subscribe(
          res => {
            this.insurances = res.insurance;
                this.otherService.getCountries().subscribe(
                  res => {
                    console.log(res);
                    this.countries = res.countries;
                    this.pass = true; //para mostrar content en html bandera
                    this.util.dismissLoading();
                  },
                  err => {
                    console.log(err);
                    this.util.dismissLoading();
                  }
                );
          },
          err => {
            console.log(err);
            this.util.dismissLoading();
          }
        );
      
      },
      err => {
        console.log(err);
        this.util.dismissLoading();
      }
    );
  }else{
    this.otherService.getInsurance().subscribe(
      res => {
        console.log(res);
        this.insurances = res.insurance;

        this.otherService.getCountries().subscribe(
          res => {
            console.log(res);
            this.countries = res.countries;
            //this.policyform.get('pais').setValue(res.policy.country);
            this.pass = true; //para mostrar content en html bandera
            this.util.dismissLoading();
          },
          err => {
            console.log(err);
            this.util.dismissLoading();
          }
        );
        //this.pass = true; //para mostrar content en html bandera

      },
      err => {
        console.log(err);
        this.util.dismissLoading();
      }
    );
  }
    /*this.policyform.get('type').setValue('file');
    this.policyform.get('file').setValue('data:image/jpeg;base64,/9j/4QEkRXhp');
    this.filespoliza.push('data:image/jpeg;base64,/9j/4QEkRXhpf');  
    this.filespoliza.push('data:image/jpeg;base64,/9j/4QEkRXhp');*/
    //get insurancies
   
  }

 

  guardar(): void {
    this.util.presentLoading();
    let typeredmedica;
    let typecarnet;
    let type;
    
    //1) Verifico si el dato de la poliza es url o archivo
    if(this.policyform.value.type == 'url'){            
      type = true;
    }else{
      type = false;
    }

    if(this.policyform.value.typecarnet == 'url'){            
      typecarnet = true;
    }else{
      typecarnet = false;
    }

    if(this.policyform.value.typered == 'url'){            
      typeredmedica = true;
    }else{
      typeredmedica = false;
    }
    
    //1
    if(type && typecarnet && typeredmedica){
      console.log(1);
      this.body = {
        
        policy_number: this.policyform.value.nropolicy,
        date_of_issue: this.policyform.value.fren,
        country: this.policyform.value.pais,
        value: this.policyform.value.valor,
        insurance_carrier: this.policyform.value.aseguradora,
        certificate: this.policyform.value.certificate,
        phone_broker:this.policyform.value.phone_broker,
        email_broker:this.policyform.value.email_broker,
        anual_amount:this.policyform.value.anual_amount,
        //type: this.policyform.value.type,        
        //url: this.policyform.value.file,
        policy_files:{
          type : this.policyform.value.type,
          url : this.policyform.value.file,
        },
        policy_carnet:{
          type : this.policyform.value.typecarnet,
          url : this.policyform.value.filecarnet,
        },
        medical_red:{
          type : this.policyform.value.typered,
          url : this.policyform.value.filered,
        }          
      };
    }
    //2
    if(type && typecarnet && !typeredmedica){
      this.body = {
        policy_number: this.policyform.value.nropolicy,
        date_of_issue: this.policyform.value.fren,
        country: this.policyform.value.pais,
        value: this.policyform.value.valor,
        insurance_carrier: this.policyform.value.aseguradora,
        anual_amount:this.policyform.value.anual_amount,
        certificate: this.policyform.value.certificate,
        phone_broker:this.policyform.value.phone_broker,
        email_broker:this.policyform.value.email_broker,
        //type: this.policyform.value.type,
        //url: this.policyform.value.file,
        policy_files:{
          type : this.policyform.value.type,
          url : this.policyform.value.file,
        },
        policy_carnet:{
          type : this.policyform.value.typecarnet,
          url : this.policyform.value.filecarnet,
        },
        medical_red:{
          type : this.policyform.value.typered,
          //file : this.policyform.value.filered,
          files : this.filesred,
        }          
      };
    }
    //Solo URL en poliza
    if(type && !typecarnet && !typeredmedica){
      this.body = {
        policy_number: this.policyform.value.nropolicy,
        date_of_issue: this.policyform.value.fren,
        country: this.policyform.value.pais,
        value: this.policyform.value.valor,
        insurance_carrier: this.policyform.value.aseguradora,
        anual_amount:this.policyform.value.anual_amount,
        certificate: this.policyform.value.certificate,
        phone_broker:this.policyform.value.phone_broker,
        email_broker:this.policyform.value.email_broker,
        //type: this.policyform.value.type,
        //url: this.policyform.value.file,
        policy_files: {        
          type: this.policyform.value.type,
          url: this.policyform.value.file,
        },
        policy_carnet:{
          type : this.policyform.value.typecarnet,
          //file : this.policyform.value.filecarnet,
          files : this.filescarnet,
        },
        medical_red:{
          type : this.policyform.value.typered,
          //file : this.policyform.value.filered,
          files : this.filesred,
        }          
      };
    }
    //4
    if(!type && !typecarnet && !typeredmedica){
      this.body = {
        policy_number: this.policyform.value.nropolicy,
        date_of_issue: this.policyform.value.fren,
        country: this.policyform.value.pais,
        value: this.policyform.value.valor,
        insurance_carrier: this.policyform.value.aseguradora,
        anual_amount:this.policyform.value.anual_amount,
        certificate: this.policyform.value.certificate,
        phone_broker:this.policyform.value.phone_broker,
        email_broker:this.policyform.value.email_broker,
        policy_files: {        
          type: this.policyform.value.type,
          files: this.filespoliza,
        },
        policy_carnet:{
          type : this.policyform.value.typecarnet,
          //file : this.policyform.value.filecarnet,
          files : this.filescarnet,
        },
        medical_red:{
          type : this.policyform.value.typered,
          //file : this.policyform.value.filered,
          files : this.filesred,
        }                      
      };
    }
    //5
    if(!type && !typecarnet && typeredmedica){
      this.body = {
        policy_number: this.policyform.value.nropolicy,
        date_of_issue: this.policyform.value.fren,
        country: this.policyform.value.pais,
        value: this.policyform.value.valor,
        insurance_carrier: this.policyform.value.aseguradora,
        anual_amount:this.policyform.value.anual_amount,
        certificate: this.policyform.value.certificate,
        phone_broker:this.policyform.value.phone_broker,
        email_broker:this.policyform.value.email_broker,
        policy_files: {        
          type: this.policyform.value.type,
          files: this.filespoliza,
        },
        policy_carnet:{
          type : this.policyform.value.typecarnet,
          //file : this.policyform.value.filecarnet,
          files : this.filescarnet,
        },
        medical_red:{
          type : this.policyform.value.typered,
          url : this.policyform.value.filered,
        }          
      };
    }
    //6
    if(!type && typecarnet && typeredmedica){
      this.body = {
        policy_number: this.policyform.value.nropolicy,
        date_of_issue: this.policyform.value.fren,
        country: this.policyform.value.pais,
        value: this.policyform.value.valor,
        insurance_carrier: this.policyform.value.aseguradora,
        anual_amount:this.policyform.value.anual_amount,
        certificate: this.policyform.value.certificate,
        phone_broker:this.policyform.value.phone_broker,
        email_broker:this.policyform.value.email_broker,
        policy_files: {        
          type: this.policyform.value.type,
          files: this.filespoliza,
        },
        policy_carnet:{
          type : this.policyform.value.typecarnet,
          url : this.policyform.value.filecarnet,
        },
        medical_red:{
          type : this.policyform.value.typered,
          url : this.policyform.value.filered,
        }          
      };
    }
    //7
    if(!type && typecarnet && !typeredmedica){
      this.body = {
        policy_number: this.policyform.value.nropolicy,
        date_of_issue: this.policyform.value.fren,
        country: this.policyform.value.pais,
        value: this.policyform.value.valor,
        insurance_carrier: this.policyform.value.aseguradora,
        anual_amount:this.policyform.value.anual_amount,
        certificate: this.policyform.value.certificate,
        phone_broker:this.policyform.value.phone_broker,
        email_broker:this.policyform.value.email_broker,
        //type: this.policyform.value.type,
        //file: this.policyform.value.file,
        policy_files: {        
          type: this.policyform.value.type,
          files: this.filespoliza,
        },
        policy_carnet:{
          type : this.policyform.value.typecarnet,
          url : this.policyform.value.filecarnet,
        },
        medical_red:{
          type : this.policyform.value.typered,
          //file : this.policyform.value.filered,
          files : this.filesred,
        }            
      };
    }
    //8
    if(type && !typecarnet && typeredmedica){
      this.body = {
        policy_number: this.policyform.value.nropolicy,
        date_of_issue: this.policyform.value.fren,
        country: this.policyform.value.pais,
        value: this.policyform.value.valor,
        insurance_carrier: this.policyform.value.aseguradora,
        anual_amount:this.policyform.value.anual_amount,
        certificate: this.policyform.value.certificate,
        phone_broker:this.policyform.value.phone_broker,
        email_broker:this.policyform.value.email_broker,
        policy_files:{
          type : this.policyform.value.type,
          url : this.policyform.value.file,
        },
        policy_carnet:{
          type : this.policyform.value.typecarnet,
          files : this.policyform.value.filecarnet,
        },
        medical_red:{
          type : this.policyform.value.typered,
          url : this.policyform.value.filered,
        }          
      };
    }

    if(this.body.policy_files.type == ""){
      this.body.policy_files = null;
    }
    if(this.body.policy_carnet.type == ""){
      this.body.policy_carnet = null;
    }
    if(this.body.medical_red.type == ""){
      this.body.medical_red = null;
    }

    if(this.type == 'edit'){
      this.policyService.updatePolicy(this.body).subscribe(
        res => {
          this.util.dismissLoading();
          console.log(res);
          this.util.presentToast("Poliza actualizada!", "succes");
          this.dismiss(true);
        },
        err => {
          this.util.dismissLoading();
          console.log(err);
        }
        );
        
    }else{
      let dataUser = JSON.parse(localStorage.getItem("userApi"));
      const object2 = Object.assign({user_id: dataUser.id}, this.body);
      this.policyService.setPolicy(object2).subscribe(
        res => {
          this.util.dismissLoading();
          console.log(res);
          this.util.presentToast("Poliza Registrada!", "succes");
          this.dismiss(true);
        },
        err => {
          this.util.dismissLoading();
          console.log(err);
        }
      );
  }
  }

  /**
   * @description Cerrar el modal
   */
  dismiss(value) {
    this.modalCtrl.dismiss({ update : value });
  }


  /**
   * @description Metodo para abrir menu desplegable y con opciones para cargar la foto
   * desde la camara o la galeria
   * 
   */
  async subirImg(value) {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Cargar mediante',
      buttons: [{
        text: 'Galeria',
        icon: 'image',
        handler: () => {
          this.util.takePicture(this.camera.PictureSourceType.PHOTOLIBRARY).then((R)=>{
            if(R != ""){
              switch (value) {
                case 'archivopoliza':
                  this.policyform.get('file').setValue(R);  
                  this.filespoliza.push(R);  
                  break;
                case 'archivocarnet':
                  this.policyform.get('filecarnet').setValue(R);    
                  this.filescarnet.push(R);
                  break;
                case 'archivored':
                  this.policyform.get('filered').setValue(R);    
                  this.filesred.push(R);
                  break;
                default:
                  break;
              }
              
            }
          });
        }
      }, {
        text: 'Tomar Foto',
        icon: 'camera',
        handler: () => {
          this.util.takePicture(this.camera.PictureSourceType.CAMERA).then((R)=>{
            if(R != ""){
              switch (value) {
                case 'archivopoliza':
                  this.policyform.get('file').setValue(R); 
                  this.filespoliza.push(R);                   
                  break;
                case 'archivocarnet':
                  this.policyform.get('filecarnet').setValue(R); 
                  this.filescarnet.push(R);   
                  break;
                case 'archivored':
                  this.policyform.get('filered').setValue(R); 
                  this.filesred.push(R);   
                  break;
                default:
                  break;
              }
              
            }
          });
        }
      }, {
        text: 'Cancelar',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    await actionSheet.present();
  }

 /**
  * @description Metodo para subir un archivo tipo doc, pdf, docx
  * @param value saber que archivo esta seleccionando (poliza, redmedica, carnet)
  */
 selectFile(value){
  (async () => {
    const file = await (<any>window).chooser.getFile('application/msword,application/pdf,image/gif,application/vnd.openxmlformats-officedocument.wordprocessingml.document');
    console.log(file ? file.name : 'canceled');
    switch (value) {
      case 'archivopoliza':
        this.policyform.get('file').setValue(file.dataURI);    
        this.filespoliza.push(file.dataURI); 
        break;
      case 'archivocarnet':
      this.policyform.get('filecarnet').setValue(file.dataURI);    
      this.filescarnet.push(file.dataURI);
        break;
      case 'archivored':
      this.policyform.get('filered').setValue(file.dataURI);   
      this.filesred.push(file.dataURI); 
      break;
      default:
        break;
    }
    
  })();
}



/**
   * 
   * @description borrar una imagen de una de las lista de los files
   * @param file contiene el objeto que sera eliminado del arreglo 
   * @param type saber que lista vamos a manipular
   */
  deleteFile(file, type) {

    switch (type) {
      case 'poliza':
        this.filespoliza = this.filespoliza.filter(item => item !== file);
        break;
      case 'carnet':
        this.filescarnet = this.filescarnet.filter(item => item !== file);
        break;
      case 'redmedica':
      this.filesred = this.filesred.filter(item => item !== file);
        break;
      default:
        break;
    }
      
  }
}