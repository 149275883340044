import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OtherService } from '../../../shared/services/other/other.service';

@Component({
  selector: 'app-addfamodule',
  templateUrl: './addfamodule.page.html',
  styleUrls: ['./addfamodule.page.scss'],
  providers: [OtherService]
})
export class AddfamodulePage implements OnInit {
  addfam: FormGroup;//form group para agregar familiares
  grupof: any = [];//array grupo familiar
  relation: any = []; //setear relaciones traidas del api  
  constructor(
    private modalCtrl: ModalController, 
    private formBuilder: FormBuilder,
    private otherService: OtherService
    ) {
    this.addfam = this.formBuilder.group({
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      sexo: ['', Validators.required],
      fnac: ['', Validators.required],
      relation: ['', Validators.required]
    });
  }

  ngOnInit() {
    if (localStorage.getItem("grupofamiliar") != null && localStorage.getItem("grupofamiliar") != undefined && localStorage.getItem("grupofamiliar") != 'undefined') {
      this.grupof = JSON.parse(localStorage.getItem("grupofamiliar"));
    }
    //traigo relations para mostrar en el select de parentesco
    this.otherService.getRelations().subscribe(
      res => {
        this.relation = res.relations;        
      },
      err => {
        console.log(err);
      }
    );//cierre service user
  }

  /**
   * @description Metodo anexar familiares al registro
   * 
   */
  agregar() {
    let fam = {
      name: this.addfam.value.nombre,
      lastname: this.addfam.value.apellido,
      gender: this.addfam.value.sexo,
      birth_date: this.addfam.value.fnac,
      relationship_id: this.addfam.value.relation
    };
    if (localStorage.getItem("grupofamiliar") == null || localStorage.getItem("grupofamiliar") == undefined || localStorage.getItem("grupofamiliar") == 'undefined') {
      var aux = [];
      aux[0] = fam;
      localStorage.setItem("grupofamiliar", JSON.stringify(aux));
      this.grupof.push(fam);
    } else {
      this.grupof = JSON.parse(localStorage.getItem("grupofamiliar"));
      this.grupof.push(fam);
      localStorage.setItem("grupofamiliar", JSON.stringify(this.grupof));
    }

    this.addfam.reset();
  }
  /**
   * 
   * @description borrar un familiar del array grupofamiliar
   * @param familiar contiene el objeto que sera eliminado del arreglo grupof
   */
  deleteFam(familiar) {
    this.grupof = this.grupof.filter(item => item !== familiar);
    localStorage.setItem("grupofamiliar", JSON.stringify(this.grupof));
  }

  /**
   * @description Cerrar el modal
   */
  dismiss() {
    this.modalCtrl.dismiss();
  }
}
