import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

const TOKEN_KEY = 'AuthToken';

@Injectable()
export class TokenStorage {

  constructor(public storage: Storage) { }

  signOut() {
    this.storage.set('AuthToken', null);
    localStorage.removeItem('AuthToken');
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.clear();
  }

  public saveToken(token: string):Promise<any> {
    console.log(token);
    return new Promise(Resolve=>{
      if(token){
        window.sessionStorage.removeItem(TOKEN_KEY);
        this.storage.set('AuthToken', null);
        this.storage.set('AuthToken', token);
        localStorage.setItem('AuthToken',token);
        Resolve();
      }else{
        Resolve();
      }
    })
  }

  public getToken(): string {
    return  localStorage.getItem('AuthToken');
  }
}