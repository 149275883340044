import { HttpClient } from '@angular/common/http';
import { API } from './../../util/constants';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class CitaMedicaService {

  constructor(private http:HttpClient) { }


  /**
   * 
   * @description consultar cita x id
   * 
   */
  public getCita(id): Observable<any>{
    
    return this.http.get<any>(""+API+"/medicalappoinments/"+id+"");
  }




  /**
   * 
   * @description Para buscar especialidades
   * @returns Observable<any> 
   */
  public buscarEspecialidad(body): Observable<any>{
    
    return this.http.post<any>(""+API+"/specialities/search", body);
  }



   /**
   * 
   * @description solicitar cita medica
   * @returns Observable<any> 
   */
  public solicitarCita(body): Observable<any>{
    
    return this.http.post<any>(""+API+"/medicalappoinments", body);
  }

}
