import { API } from './../../util/constants';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http:HttpClient,public storage: Storage) {
  }


  /**
   * 
   * @description Obtiene los datos de usuario logueado 
   * @author Carlos Diaz
   * @returns Observable<any> 
   */
  getDataUser(): Observable<any>{
    console.log(localStorage.getItem('AuthToken'));
    var headers = {headers: {'Authorization':'Bearer '+ localStorage.getItem('AuthToken')}};
    return this.http.get<any>(API+"/auth/user", headers);
  }


  /**
   * @description Editar La informacion del usuario
   * @param body body del request
   * 
   */
  public editUser(body): Observable<any>{
    let dataUser = JSON.parse(localStorage.getItem("userApi"));
    return this.http.put<any>(""+API+"/user/"+dataUser.id+"", body);
  }


  /**
   * 
   * @description registrar el step1, step2 o step3 new user 
   * @author Carlos Diaz
   * @returns Observable<any> 
   */
  public registroSteps(registro): Observable<any>{
    return this.http.post<any>(""+API+"/register", registro);
  }
  

  public editDependent(body,id): Observable<any>{
    
    return this.http.put<any>(""+API+"/dependent/"+id+"", body);
  }
  /**
   * @description Borrar un dependiente
   * @param id Id dependiente o familiar 
   */
  public deleteDependent(id): Observable<any>{
    
    return this.http.delete<any>(""+API+"/dependent/"+id+"");
  }

}
