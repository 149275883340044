import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TermsService } from '../../shared/services/terms/terms.service';

@Component({
	selector: 'app-terms',
	templateUrl: './terms.page.html',
	styleUrls: ['./terms.page.scss']
})
export class TermsPage implements OnInit {
	termsHtml = '';

	constructor(
		private modalCtrl: ModalController,
		private terms: TermsService
	) {}

	ngOnInit() {
		this.terms.getTerms().subscribe(res => {
			console.log('res: ', res);
			const terms = document.getElementById('terms');
			terms.innerHTML = res.terms.content;
		});
	}

	dismiss(value) {
		this.modalCtrl.dismiss({ update: value });
	}
}
