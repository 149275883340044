import { Component, OnInit } from '@angular/core';
import { NavController, NavParams, ModalController } from '@ionic/angular';
import { OtherService } from '../../shared/services/other/other.service';
import { Util } from './../../shared/util/util';


@Component({
  selector: 'app-change-card',
  templateUrl: './change-card.page.html',
  styleUrls: ['./change-card.page.scss'],
})
export class ChangeCardPage implements OnInit {
	public cards = [];
	public plan:any;
  constructor(public navParams: NavParams,private modalCtrl: ModalController,private os:OtherService,private util:Util) {
 		let usrAux = JSON.parse(localStorage.getItem("userApi"));
 		this.cards = usrAux.cards;
 		this.plan = this.navParams.get('plan');
 		console.log(usrAux);
  }

  ngOnInit() {

  }

  asignCard(card){
  	this.util.presentLoading();
  	this.os.chnageCard({'id':this.plan.id, 'card_id':card.id}).subscribe(
    	res => {
      	this.util.dismissLoading();
        this.util.presentToast("Tarjeta Cambiada", "succes");
        this.dismiss(true);
      },
      err => {
      	this.util.dismissLoading();
        console.log(err);
      }
    );
  }

	dismiss(value) {
    this.modalCtrl.dismiss({ update : value });
  }

}
