import { Component, OnInit, Input, NgZone } from '@angular/core';
import { Util } from './../../../shared/util/util';
import { OtherService } from './../../../shared/services/other/other.service';
import { ModalController, ToastController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Geolocation } from '@ionic-native/geolocation/ngx';
declare var google

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

    @Input() value: any;
    @Input() id: any;
    
    mapRef = null;
    markers:any = [];
    address: FormGroup;
    autocompleteItems: any[];
    GoogleAutocomplete:any;
    autocomplete:any;
    geocoder:any;
    addressSelected: any;
    constructor(
      private modalCtrl:ModalController,
      private formBuilder: FormBuilder,
      private ot:OtherService,
      private tc: ToastController,
      private util:Util,
      private geolocation: Geolocation,
      private zone: NgZone,
    ) { 
      this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
      this.autocomplete = { input: '' };
      this.autocompleteItems = [];
      this.geocoder = new google.maps.Geocoder;
      this.address = this.formBuilder.group({
        name: ['', Validators.required],
        line: ['', Validators.required],
        line2: [''],
        line3: [''],
        line4: [''],
        lat: ['', Validators.required],
        lng: ['', Validators.required],
        user_id: ['', Validators.required],
        contact: ['', Validators.required],
        codeArea: ['', Validators.required],      
      });
      this.address.get('codeArea').setValue("+507");
      let usrAux = JSON.parse(localStorage.getItem("userApi"));
      this.address.get('user_id').setValue(usrAux.id);

    }

    ngOnInit() {
      if(this.value == 'EDIT'){
          //Load direcciones
        this.ot.getAddressById(this.id).subscribe(
          res => {
            this.address.get('name').setValue(res.address.name);    
            this.address.get('line').setValue(res.address.line);  
            this.address.get('line2').setValue(res.address.line2);
            this.address.get('line3').setValue(res.address.line3);
            this.address.get('line4').setValue(res.address.line4);
            this.address.get('lat').setValue(res.address.lat);  
            this.address.get('lng').setValue(res.address.long);
            this.address.get('lng').setValue(res.address.long);
            this.address.get('user_id').setValue(res.address.user_id);
            var nrotlf = res.address.contact;
            var codarea = nrotlf.substring(0, nrotlf.indexOf(" "));
            nrotlf = nrotlf.substring(nrotlf.indexOf(" ") + 1);          
            this.address.get('contact').setValue(nrotlf);
            this.address.get('codeArea').setValue(codarea);
            this.loadMap();
          },
          err => {
            this.dismiss('false');
            console.log(err);
          }
        );    
      }else{
      this.loadMap();
      }
    }

    selectSearchResult(item){
      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      this.markers = [];
      this.autocompleteItems = [];
      this.geocoder.geocode({'placeId': item.place_id}, (results, status) => {
        if(status === 'OK' && results[0]){
          let position = {
              lat: results[0].geometry.location.lat,
              lng: results[0].geometry.location.lng
          };
          let marker = new google.maps.Marker({
            position: results[0].geometry.location,
            map: this.mapRef,
            draggable: true
          });
          
          var latitude = results[0].geometry.location.lat();
          var longitude = results[0].geometry.location.lng();
          this.address.get('lat').setValue(latitude);
          this.address.get('lng').setValue(longitude);

          this.markers.push(marker);
          this.autocomplete.input = item.structured_formatting.main_text;
          this.mapRef.setCenter(results[0].geometry.location);
        }
      })
    }

    updateSearchResults(){
      if (this.autocomplete.input == '') {
        this.autocompleteItems = [];
        return;
      }

      this.GoogleAutocomplete.getPlacePredictions({ input: this.autocomplete.input,  componentRestrictions: { country: 'PA' } },
        (predictions, status) => {
          this.autocompleteItems = [];
          this.zone.run(() => {
            predictions.forEach((prediction) => {
              this.autocompleteItems.push(prediction);
            });
          });
        });
    }

   
    guardar(){
      if(this.address.valid){
        this.util.presentLoading();
        if(this.value == "ADD"){
        let body={
          name: this.address.value.name,
          line: this.address.value.line,
          line2: this.address.value.line2,
          line3: this.address.value.line3,
          line4: this.address.value.line4,
          location:{
            lat:this.address.value.lat,
            long: this.address.value.lng
          },
          user_id:this.address.value.user_id,
          contact:this.address.value.codeArea+" "+this.address.value.contact

        }
        this.ot.setAddress(body).subscribe(
          res => {   
            try{
              console.log(res);
              res.errors[0];
              this.util.dismissLoading();
              alert(res.errors[0]);          
            }catch(err){
              this.util.dismissLoading();
              console.log(res);
              this.util.presentToast("Direccion Agregada", "succes");
              this.dismiss("true");
            }          
                    

          },
          err => {
            console.log(err);
            this.util.dismissLoading();
            
            //console.log(err.errors[0]);
          }
        );

        }else{

          //case edit
          let body={
            name: this.address.value.name,
            line: this.address.value.line,
            line2: this.address.value.line2,
            line3: this.address.value.line3,
            line4: this.address.value.line4,
            location:{
              lat:this.address.value.lat,
              long: this.address.value.lng
            },
            user_id:this.address.value.user_id,
            contact:this.address.value.codeArea+" "+this.address.value.contact
      
          }

          this.ot.editAddress(body, this.id).subscribe(
            res => {   
              try{
                console.log(res);
                res.errors[0];
                this.util.dismissLoading();
                alert(res.errors[0]);          
              }catch(err){
                this.util.dismissLoading();
                console.log(res);
                this.util.presentToast("Direccion Actualizada", "succes");
                this.dismiss("true");
              }          
                    
                      
      
            },
            err => {
              console.log(err);
              this.util.presentToast("No Se Ha Podido Actualizar La Direccion", "alert");
              this.util.dismissLoading();
              
              //console.log(err.errors[0]);
            }
          );

        }
      }else{
        this.util.presentToast("Completa todos los campos y seleccione una ubicacion en el mapa", "alert");
      }

    }


    /**
     * @param value "true" para salir y actualizar la location con location reload o "false" solo para cerrar 
     * @description Cerrar el modal
     */
    dismiss(value) {
      this.address.reset();
      this.modalCtrl.dismiss({ update : value });
    }



    async loadMap() {
      
      var that = this;
      if(this.value == "ADD"){
        //get location
        this.geolocation.getCurrentPosition().then((resp) => {
         
         
         
        // create LatLng object
        let myLatLng = {lat: resp.coords.latitude, lng: resp.coords.longitude};
        const mapEle: HTMLElement = document.getElementById('map');
        this.mapRef = new google.maps.Map(mapEle, {
          center: myLatLng,
          zoom: 15,
          mapTypeId: 'roadmap',        
          zoomControl: true,        
          mapTypeControl: false,
          scaleControl: true,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false
        });                      
          


        google.maps.event.addListener(this.mapRef, "click", function (e) {
        
          that.markers.forEach(element => {
              element.setMap(null);
          });
          //lat and lng is available in e object
          let lat = e.latLng.lat();
          let lng = e.latLng.lng(); 
    
          that.address.get('lat').setValue(lat);
          that.address.get('lng').setValue(lng);              
          
          that.addMaker(lat,lng);
        });

         }).catch((error) => {
           console.log('Error getting location', error);
         });
         



     
      }else{
        
        let myLatLng = {lat: Number(this.address.value.lat), lng: Number(this.address.value.lng)};
        const mapEle: HTMLElement = document.getElementById('map');
        this.mapRef = new google.maps.Map(mapEle, {
          center: myLatLng,
          zoom: 15,
          mapTypeId: 'roadmap',                
          zoomControl: true,        
          mapTypeControl: false,
          scaleControl: true,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false
        });

        this.addMaker(Number(this.address.value.lat),Number(this.address.value.lng));

        google.maps.event.addListener(this.mapRef, "click", function (e) {
        
          that.markers.forEach(element => {
              element.setMap(null);
          });
          //lat and lng is available in e object
          let lat = e.latLng.lat();
          let lng = e.latLng.lng(); 
    
          that.address.get('lat').setValue(lat);
          that.address.get('lng').setValue(lng);              
          
          that.addMaker(lat,lng);
        });

      }
    /*  google.maps.event
      .addListenerOnce(this.mapRef, 'idle', () => {
       
        this.addMaker(8.537981, -80.782127);
      });*/
      

    }

    private addMaker(lat: number, lng: number) {
      const marker = new google.maps.Marker({
        position: { lat, lng },
        map: this.mapRef,
        title: 'location!'
      });
      this.markers.push(marker);
    }

}
