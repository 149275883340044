import { EditFamiliarPage } from './edit-familiar/edit-familiar.page';
import { Util } from './../../../shared/util/util';
import { Component, OnInit } from '@angular/core';
import { ModalController  } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../shared/services/user/user.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.page.html',
  styleUrls: ['./edit-user.page.scss'],
  providers: [UserService]
})
export class EditUserPage implements OnInit {
  user:FormGroup;
  dependents:any=[];
  constructor(
    private modalCtrl:ModalController,
    private formBuilder: FormBuilder,
    private userService:UserService,
    
    
    private util:Util,
  ) { 

    this.user = this.formBuilder.group({
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      sexo: ['', Validators.required],
      fnac: ['', Validators.required],      
      phone: [''],

    });

    let usrAux = JSON.parse(localStorage.getItem("userApi"));
    this.user.get('nombre').setValue(usrAux.name);
    this.user.get('apellido').setValue(usrAux.lastname);
    this.user.get('sexo').setValue(usrAux.gender);
    this.user.get('fnac').setValue(usrAux.birth_date);
    this.user.get('phone').setValue(usrAux.phone);

    this.dependents=usrAux.dependents;    
  }

  ngOnInit() {
    
  }

  /**
   * @description Metodo para guardar informacion personal del usuario no aplica para familiares dependientes
   */
  guardar(){    
    this.util.presentLoading();
    let body = {
      name: this.user.value.nombre,
      lastname: this.user.value.apellido,
      gender: this.user.value.sexo,
      birth_date: this.user.value.fnac,
      phone: this.user.value.phone      
    }  


    this.userService.editUser(body).subscribe(
      res => {
        console.log(res);
        // localStorage.setItem("userApi", JSON.stringify(res.user));
        //vuelvo a traerme la info del user ya que el devuelto en esta respuesta no me trae los dependientes y corrumpe la app en la vista de home
        this.userService.getDataUser().subscribe(res => {
          this.util.dismissLoading();
          localStorage.setItem("userApi", JSON.stringify(res.user));
          this.util.presentToast("Datos actualizados con exito", "succes");
          this.dismiss("true");  
        },err=>{
          this.util.dismissLoading();
          //se hicieron los cambios pero no se puedo traer el user necesario para mostrar la vista de home 
          this.util.presentToast("Datos actualizados con exito", "succes");
          this.dismiss("false");  
        });
        
      },
      err => {
        this.util.dismissLoading();
        this.util.presentToast("Ha ocurrido un problema al guardar los cambios", "alert");
      }
    );    

  }


 
  /**
   *  @description cerrar el modal 
   * @param value true o false para que al cerrar el modal se actualice la location donde este
   */
  dismiss(value) {
    this.modalCtrl.dismiss({ update : value } );
  }

    /**
    * @async
    * @description //editar los dependientes o familiares
    * 
    */
   async editFamiliar(fam) {
     //console.log(fam);
    //create modal
    let modal = await this.modalCtrl.create({
      component: EditFamiliarPage,
      componentProps: { value: fam },
      cssClass: 'inset-modal'
    });
    //Cuando se cierre el modal...
    modal.onWillDismiss().then(data => {
      
      if(data.data.update == "true"){
       /* var a = setInterval(function(data){
          location.reload();
          clearInterval(a);
        },5000)*/
        
        
      }
      
    });
    return await modal.present();

  }



   /**
   * @description Eliminar un dependiente o familiar
   */
  deleteFam(id){
    this.userService.deleteDependent(id).subscribe(
      res => {
        console.log(res);
        // localStorage.setItem("userApi", JSON.stringify(res.user));
        //vuelvo a traerme la info del user ya que el devuelto en esta respuesta no me trae los dependientes y corrumpe la app en la vista de home
        this.userService.getDataUser().subscribe(res => {
          localStorage.setItem("userApi", JSON.stringify(res.user));
          this.util.presentToast("Datos actualizados con exito", "succes");
          this.dismiss("false");  
        },err=>{
          //se hicieron los cambios pero no se puedo traer el user necesario para mostrar la vista de home 
          this.util.presentToast("Datos actualizados con exito", "succes");
          this.dismiss("false");  
        });
        
      },
      err => {
        this.util.presentToast("Ha ocurrido un problema al guardar los cambios", "alert");
      }
    );    
  }



}
