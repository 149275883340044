import { API } from './../../util/constants';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private http:HttpClient) { 

  }




  /**
   * 
   * @description Para registrar solicitud de facturas
   * @returns Observable<any> 
   */
  public solicitarFacturas(body): Observable<any>{
    
    return this.http.post<any>(""+API+"/invoices", body);
  }


  /**
   * 
   * @description obtener detalles de la solicitud de facturas mediante id de la solicitud
   * @returns Observable<any> 
   */
  public getInvoiceRequest(id): Observable<any>{
    
    return this.http.get<any>(""+API+"/invoices/"+id+"");
  }

}
