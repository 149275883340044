import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressComponent } from './address/address.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { IonicAddressComponent } from './ionic/ionic-address/ionic-address.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot()
  ],
  exports:[AddressComponent,IonicModule,IonicAddressComponent],
  declarations: [AddressComponent, IonicAddressComponent]
})
     
export class ComponentsModule { }
