import { API } from './../../util/constants';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OtherService {

  constructor(private http:HttpClient) { }
  /**
   * @description Consultar las relaciones existentes para el parentezco de un familiar
   */
  public getRelations(): Observable<any>{
    return this.http.get<any>(""+API+"/relations");
  }
  /**
   * @description Consultar frecuencias (semanal, mensual, cada 15 dias, etc...)
   */
  public getFrequency(): Observable<any>{
    return this.http.get<any>(""+API+"/frequency");
  }

  /**
   * @description 
   */
  public getCountries(): Observable<any>{
    return this.http.get<any>(""+API+"/countries");
  }

  /**
   * @description 
   */
  public getInsurance(): Observable<any>{
    return this.http.get<any>(""+API+"/insurance");
  }

  /**
    * @param body  body del request
   * @description Registrar una consulta adiciional
   */
  public setServAdicional(body): Observable<any>{
    return this.http.post<any>(""+API+"/aditional",body);
  }



  /**
   * 
   * @description obtener detalles de la solicitud de adicionales mediante id de la solicitud
   * @returns Observable<any> 
   */
  public getAditionalRequest(id): Observable<any>{
    
    return this.http.get<any>(""+API+"/aditional/"+id+"");
  }



 /**
    * @param creditCard  body del request
   * @description Registrar tarjeta de credito pago del plan 
   */
  public payPlan(creditCard,type,id,frequency,price,plan_id): Observable<any>{
    let dataUser = JSON.parse(localStorage.getItem("userApi"));
    return this.http.post<any>(""+API+"/order/payment/user", {
        'payment': {
            'type':'credit_card',
             creditCard
        },
        'user_id': dataUser.id,
        'type':type,
        'id':id,
        frequency,
        price,
        plan_id
    });
  }


  public addCard(creditCard): Observable<any>{
    let dataUser = JSON.parse(localStorage.getItem("userApi"));
    return this.http.post<any>(""+API+"/metropago/card/create", {'creditCard': creditCard, 'user_id': dataUser.id } );
  }

  public primaryCard(creditCard): Observable<any>{
    let dataUser = JSON.parse(localStorage.getItem("userApi"));
    return this.http.post<any>(""+API+"/metropago/card/principal", {'id': creditCard.id, 'user_id': dataUser.id } );
  }

  public disabledCard(creditCard): Observable<any>{
    let dataUser = JSON.parse(localStorage.getItem("userApi"));
    return this.http.post<any>(""+API+"/metropago/card/disable", {'id_card': creditCard.id, 'user_id': dataUser.id } );
  }

  /**SACAR ADDRESS DE AQUI CREAR SU PROPIO SERVICIO */
  /**
   * @description Obtener direcciones registradas por el usuario
   */
  public getAddress(): Observable<any>{
    return this.http.get<any>(""+API+"/address");
  }
  /**
   * @description Obtener direcciones by id del usuario
   */
  public getAddressById(id): Observable<any>{
    return this.http.get<any>(""+API+"/address/"+id+"");
  }

   /**
    * @param body  body del request
   * @description Registrar una direccion
   */
  public setAddress(body): Observable<any>{
    return this.http.post<any>(""+API+"/address",body);
  }

   /**
    * @param body  body del request
   * @description editar una direccion
   */
  public editAddress(body, id): Observable<any>{
    return this.http.put<any>(""+API+"/address/"+id+"",body);
  }
   /**
    * 
   * @description eliminar una direccion
   */
  public deleteAddress(id): Observable<any>{
    return this.http.delete<any>(""+API+"/address/"+id+"");
  }
  

   /**
    * 
   * @description eliminar un file de la poliza
   */
  public deleteFile(id): Observable<any>{
    return this.http.delete<any>(""+API+"/media/policy/"+id+"");
  }

  /***/
  public chnageCard(body): Observable<any>{
    return this.http.put<any>(""+API+"/subscription/card",body);
  }
  
}
