import { Component, OnInit, Input } from '@angular/core';
import { ModalController  } from '@ionic/angular';
import { CitaMedicaService } from './../../../../shared/services/citaMedica/cita-medica.service';
import { MedicineService } from './../../../../shared/services/medicines/medicine.service';
import { InvoiceService } from '../../../../shared/services/invoices/invoice.service';
import { OtherService } from '../../../../shared/services/other/other.service';


@Component({
  selector: 'app-detalles-solicitud',
  templateUrl: './detalles-solicitud.page.html',
  styleUrls: ['./detalles-solicitud.page.scss'],
})
export class DetallesSolicitudPage implements OnInit {
  @Input() value: any;
  @Input() type: any;

  cita:any='';
  medicina:any='';
  factura:any='';
  adicional:any='';

  constructor( 
    private modalCtrl:ModalController,
    private cms:CitaMedicaService,
    private ms:MedicineService,
    private is:InvoiceService,
    private os:OtherService    
    ) { }

  ngOnInit() {
    switch (this.type) {
      case 'medicinas':
      this.ms.getMedicationRequest(this.value).subscribe(
        res => {        
          console.log(res); 
          this.medicina = res.request;        
        },
        err => {
          console.log(err);
        }
      );
      console.log(this.value);
        break;
      case 'facturas':
      this.is.getInvoiceRequest(this.value).subscribe(
        res => {        
          console.log(res); 
          this.factura = res.request;        
        },
        err => {
          console.log(err);
        }
      );
      console.log(this.value);
        break;
      case 'adicional':
      this.os.getAditionalRequest(this.value).subscribe(
        res => {        
          console.log(res); 
          this.adicional = res.data;        
        },
        err => {
          console.log(err);
        }
      );
     
      console.log(this.value);
        break;
      case 'citas':
      this.cms.getCita(this.value).subscribe(
        res => {        
          console.log(res); 
          this.cita = res.appointment;        
        },
        err => {
          console.log(err);
        }
      );
     
        break;
      default:
        break;
    }

    console.log(this.type);
  }



  /**
   *  @description cerrar el modal 
   * @param value true o false para que al cerrar el modal se actualice la location donde este
   */
  dismiss(value) {
    this.modalCtrl.dismiss({ update : value } );
  }

}
