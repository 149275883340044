import { AuthGuard } from './../shared/guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },

  { path: 'register', loadChildren: './register/register.module#RegisterPageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule', canActivate: [AuthGuard] },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' , canActivate: [AuthGuard]},  
  { path: 'singup', loadChildren: './singup/singup.module#SingupPageModule', canActivate: [AuthGuard] },
  { path: 'solicitudes', loadChildren: './solicitudes/solicitudes.module#SolicitudesPageModule' , canActivate: [AuthGuard]},
  { path: 'solicitarMedicina', loadChildren: './solicitudes/solicitar-medicina/solicitar-medicina.module#SolicitarMedicinaPageModule', canActivate: [AuthGuard] },
  { path: 'historialSolicitudes', loadChildren: './solicitudes/historial-solicitudes/historial-solicitudes.module#HistorialSolicitudesPageModule', canActivate: [AuthGuard] },
  { path: 'citaMedica', loadChildren: './solicitudes/cita-medica/cita-medica.module#CitaMedicaPageModule', canActivate: [AuthGuard] },
  { path: 'logout', loadChildren: './logout/logout.module#LogoutPageModule', canActivate: [AuthGuard] },
  { path: 'solicitarFacturas', loadChildren: './solicitudes/solicitar-facturas/solicitar-facturas.module#SolicitarFacturasPageModule' },
  { path: 'sol-adicional', loadChildren: './solicitudes/sol-adicional/sol-adicional.module#SolAdicionalPageModule' },
  { path: 'Transactions', loadChildren: './transactions/transactions.module#TransactionsPageModule' },
  { path: 'cards', loadChildren: './cards/cards.module#CardsPageModule' },
  { path: 'poliza', loadChildren: './poliza/poliza.module#PolizaPageModule' },
  { path: 'terms', loadChildren: './terms/terms.module#TermsPageModule' },
  { path: 'plans', loadChildren: './plans/plans.module#PlansPageModule' },
  { path: 'plan-details/:id', loadChildren: './plan-details/plan-details.module#PlanDetailsPageModule' },
  { path: 'change-card', loadChildren: './change-card/change-card.module#ChangeCardPageModule' },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
