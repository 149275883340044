import { Component, OnInit,Input } from '@angular/core';
import { ModalController,NavParams,PopoverController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OtherService } from './../../../shared/services/other/other.service';
import { Util } from './../../../shared/util/util';
import { TermsPage } from '../../terms/terms.page';



@Component({
  selector: 'app-pay',
  templateUrl: './pay.page.html',
  styleUrls: ['./pay.page.scss'],
  providers: [OtherService]
})
export class PayPage implements OnInit {
  card:FormGroup;
  public type_action:null;
  constructor(
    private modalCtrl:ModalController,
    private formBuilder: FormBuilder,
    private ot:OtherService,
    private util:Util,
    private params: NavParams,
    private router: Router
    ) {
      this.type_action = params.get('type');
      this.card = this.formBuilder.group({
        name: ['', Validators.required],
        card_number: ['', Validators.required],
        cvv: ['', Validators.required],
        date_of_issue: ['', Validators.required], 
        terms_cond: [false, Validators.required]
      });
    }

  ngOnInit() {
  }

  /**
   *  @description cerrar el modal 
   * @param value true o false para que al cerrar el modal se actualice la location donde este
   */
  dismiss(value) {
    this.modalCtrl.dismiss({ update : value } );
  }

  async showTerms(){
    //open modal
    //create modal
    let modal = await this.modalCtrl.create({
      component: TermsPage,
      cssClass: 'inset-modal'
    });
    //Cuando se cierre el modal...
    modal.onWillDismiss().then(data => {
      console.log(data);
    });
    return await modal.present();
  }

 /* private creditcard(): string {
    const config: BrMaskModel = new BrMaskModel();
    config.mask = '0000 0000 0000 0000'; 
           
    8//return this.br.writeCreateValue('05', config);
  }*/

  pagar(){
    if(this.card.value.terms_cond){
      if(this.card.valid){
        this.util.presentLoading();
        let a  = this.card.value.card_number;
        a = a.replace(/ /g,"");
        let b = this.card.value.date_of_issue;
        b = b.replace('/',"");
        let creditCard = {
          name: this.card.value.name,
          cvv: this.card.value.cvv,
          card_number: a,
          date_of_issue: b      
        }
       if(this.type_action == 'add'){
        this.ot.addCard(creditCard).subscribe(
          res => {
              this.util.dismissLoading();
              this.util.presentToast("Tarjeta registrada correctamente","succes");          
              this.dismiss(true);
            },
            err => {
              if(err.error.message != null){
                this.util.presentToast(err.error.message, "alert");  
              }else{
                this.util.presentToast(err.statusText, "alert");  
              }
              this.util.dismissLoading();
            }
          );
       }else{
          this.ot.payPlan(creditCard,
                          'renovation',
                          this.params.get('value'),
                          this.params.get('frequency'),
                          this.params.get('price'),
                          this.params.get('plan_id')).subscribe(
            res => {
             
              this.util.dismissLoading();
              this.util.presentToast("Se ha realizado el pago satisfactoriamente","succes");          
              this.dismiss(true);
              this.router.navigateByUrl('/home');
            },
            err => {
              if(err.error.message != null){
                this.util.presentToast(err.error.message, "alert");  
              }else{
                this.util.presentToast(err.statusText, "alert");  
              }
              this.util.dismissLoading();
            }
          );  
        }
      }
    }else{
      this.util.presentToast("Debe aceptar los términos y condiciones","alert");          
    }
  } 
}
