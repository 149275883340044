import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
  
})
export class AppComponent {
  public appPages = [
    {
      title: 'Perfil',
      url: '/home',
      icon: 'contact'
    },
    {
      title: 'Póliza',
      url: '/poliza',
      icon: 'heart'
    },
    {
      title: 'Solicitudes',
      url: '/solicitudes',
      icon: 'clipboard'
    },
    {
      title: 'Planes',
      url: '/plans',
      icon: 'albums'
    },
    {
      title: 'Transacciones',
      url: '/Transactions',
      icon: 'list-box'
    },
    {
      title: 'Pagos',
      url: '/cards',
      icon: 'card'
    },
    {
      title: 'Historial de solicitudes',
      url: '/historialSolicitudes',
      icon: 'paper'
    },
    {
      title: 'Cerrar Sesión',
      url: '/logout',
      icon: 'exit'
    }
  ];


  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }


  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleLightContent();
        this.splashScreen.hide();
    });
  }
}
