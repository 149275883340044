import { AuthGuard } from './../shared/guards/auth.guard';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AddfamodulePageModule } from './singup/addfamodule/addfamodule.module';
import {EditUserPageModule} from './home/edit-user/edit-user.module';
import {AddressPageModule} from './home/address/address.module';
import {AddressPage} from './home/address/address.page';
import { PayPageModule } from './home/pay/pay.module';
import {EditPolicyPageModule} from './home/edit-policy/edit-policy.module';
import {DetallesSolicitudPageModule} from './solicitudes/historial-solicitudes/detalles-solicitud/detalles-solicitud.module';
import { IonicStorageModule } from '@ionic/storage';
import { Camera } from '@ionic-native/camera/ngx';
import { Firebase } from '@ionic-native/firebase/ngx';
import { AngularFireModule } from 'angularfire2'; 
import { AngularFireAuth } from 'angularfire2/auth';
import { environment } from '../environments/environment';
import { File } from '@ionic-native/file/ngx';
//import { Facebook } from '@ionic-native/facebook/ngx';
import { InterceptService } from './../shared/services/intercept/intercept.service';
import { TokenStorage } from '../shared/util/token.storage';
//import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { UrlContentPageModule } from './home/url-content/url-content.module';
import { Chooser } from '@ionic-native/chooser/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { PopoverPageModule } from './home/popover/popover.module';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { TermsPageModule } from './terms/terms.module';
import { PlansPipe } from './plans.pipe';
import { ChangeCardPageModule } from './change-card/change-card.module';


//import { Base64 } from '@ionic-native/base64/ngx';
//import { GoogleMaps } from '@ionic-native/google-maps/ngx';
@NgModule({
  declarations: [AppComponent, PlansPipe],
  exports: [],
  entryComponents: [],
  imports: [  
  BrowserModule,     
    IonicModule.forRoot({hardwareBackButton: false}),     
    AppRoutingModule,
    DetallesSolicitudPageModule,
    AddfamodulePageModule,
    EditUserPageModule,
    TermsPageModule,
    AddressPageModule,
    PayPageModule,
    ChangeCardPageModule,
    UrlContentPageModule,
    PopoverPageModule,
    EditPolicyPageModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig)
  ],  
  providers: [
    StatusBar,
    SplashScreen,
    File,
    FilePath,
    Camera,
    Firebase,  
    //GooglePlus,         
    //Facebook,    
    AngularFireAuth,
    Chooser,
    Geolocation,    
    CallNumber,
    InAppBrowser,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    TokenStorage,
    EmailComposer,
    AuthGuard,
    FileOpener,
    FileTransfer,
    DocumentViewer
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
