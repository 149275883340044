import { UserService } from './../services/user/user.service';
import { environment } from './../../environments/environment';
import { Injectable, Component } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import 'firebase/auth';

import { resolve } from 'dns';
import { utils } from 'protractor';
import { TokenStorage } from '../util/token.storage';
@Injectable({
  providedIn: 'root'
})


export class AuthGuard implements CanActivate {
  isLoggin:boolean=false;
  
  constructor(
    private router:Router,
    private userService:UserService,
    private token:TokenStorage
  ){
    
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebaseConfig);
    }   
   
  }
  async canActivate(route: ActivatedRouteSnapshot){
    let dataUser = JSON.parse(localStorage.getItem("userApi"));

     const aux = await this.isAuth();
      console.log(aux);
      //true hay usuario authenticado
      if(aux){
         console.log(route);
        if(route.url[0].path == 'login'){
          console.log(route.url[0].path);
          this.redirectLoginSucces();
          //return false para no mostrar la vista de login
          return false;
        }else{
          console.log(dataUser);
          switch (dataUser.status_account) {
             case 1:
              if(route.url[0].path == 'singup'){
                this.router.navigate(['/solicitudes']);
                
              }
              break;
              case 2:
              if(route.url[0].path == 'singup'){
                this.router.navigate(['/home']);
                
                
              }
              break;
              case 3:
              if(route.url[0].path == 'singup'){
                this.router.navigate(['/home']);
                
              }
              break;
              case 4:
              if(route.url[0].path == 'singup'){
                this.router.navigate(['/home']);
                
              }
              break;
            default:
              return true;
            break;
          }
          
         // console.log("aaaaaa", route.url[0].path);
          return true;
          //si el usuario autenticado intenta ir a otra vista cualquiera se retorna true 
          
        }

      //false no hay user auth
      }else{
        //si no hay user authenticado y se hace llamado distinto al path login     
        if(route.url[0].path != 'login'){     
          //no se le permite el acceso y se redirige al login 
          this.router.navigate(['/login']);
          //return false para no mostrar vista solicitada
           return false;
        }else{
          //si no hay user authenticado y se hace un llamado al path /login se retorna true para permitir loguearse
          return true;
        }
      }
   
  }
  /**
   * @description devuelve "true" si hay usuario authenticado "false" si no lo hay
   */
  isAuth():Promise<boolean> {
    let that = this;
   return new Promise(Resolve => {
    firebase.auth().onAuthStateChanged(function(user) {
      
      if (user) {
        console.log(user);
        that.token.saveToken(user['_lat']).then((R)=>{                                      
            Resolve(true);
        });       
      } else {
        Resolve(false);
      }
    });    
   })    
  }





  /**
   * @description saber para donde redireccionar dependiendo el user
   */
  redirectLoginSucces() {
    var that = this;
    this.userService.getDataUser().subscribe(
        res => {
          console.log(res);
          localStorage.setItem("userApi", JSON.stringify(res.user));
          if (res.user.status_account == 1) {                    
            //si ya completo su registro envio al home
            this.router.navigate(['/solicitudes']);
  
          } else {
            //enviar al step de registro correspondiente
            switch (res.user.step_register) {
              case 1:
                //that.router.navigateByUrl('/singup');   
                this.router.navigate(['/singup', { step: 0 }]);
                break;
              case 2:
                this.router.navigate(['/singup', { step: 1 }]);
                break;
              case 3:
                this.router.navigate(['/singup', { step: 2 }]);
                break;
              default:
                this.router.navigate(['/singup', { step: 0 }]);
                break;
            }//cierre switch                
          }//cierre else
        },
        err => {
         console.log(err);
        }
      );//cierre service user
    }
}
