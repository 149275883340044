import { Util } from './../../../../shared/util/util';
import { UserService } from './../../../../shared/services/user/user.service';
import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-familiar',
  templateUrl: './edit-familiar.page.html',
  styleUrls: ['./edit-familiar.page.scss'],
  providers: [UserService]
})
export class EditFamiliarPage implements OnInit {
  user:FormGroup;
  @Input() value: any;
  constructor(
    private modalCtrl:ModalController,
    private formBuilder: FormBuilder,
    private userService:UserService,    
    private util:Util
    ) {

      this.user = this.formBuilder.group({
        nombre: ['', Validators.required],
        apellido: ['', Validators.required],
        sexo: ['', Validators.required],
        fnac: ['', Validators.required],      
      });


     }

  ngOnInit() {
    //console.log(this.value);
     
    this.user.get('nombre').setValue(this.value.name);
    this.user.get('apellido').setValue(this.value.lastname);
    this.user.get('sexo').setValue(this.value.gender);
    this.user.get('fnac').setValue(this.value.birth_date);
    

  }
  /**
   * 
   * @description Guardar actualizacion de familiar
   * 
   */
  guardar(){
    this.util.presentLoading();
    let body = {
      name: this.user.value.nombre,
      lastname: this.user.value.apellido,
      gender: this.user.value.sexo,
      birth_date: this.user.value.fnac      
    }  


    this.userService.editDependent(body, this.value.id).subscribe(
      res => {
        console.log(res);
        // localStorage.setItem("userApi", JSON.stringify(res.user));
        //vuelvo a traerme la info del user ya que el devuelto en esta respuesta no me trae los dependientes y corrumpe la app en la vista de home
        this.userService.getDataUser().subscribe(res => {
          this.util.dismissLoading();
          localStorage.setItem("userApi", JSON.stringify(res.user));
          this.util.presentToast("Datos actualizados con exito", "succes");
          this.dismiss("true");  
        },err=>{
          this.util.dismissLoading();
          //se hicieron los cambios pero no se puedo traer el user necesario para mostrar la vista de home 
          this.util.presentToast("Datos actualizados con exito", "succes");
          this.dismiss("false");  
        });
        
      },
      err => {
        this.util.dismissLoading();
        this.util.presentToast("Ha ocurrido un problema al guardar los cambios", "alert");
      }
    );    
  }

 
  /**
   *  @description cerrar el modal 
   * @param value true o false para que al cerrar el modal se actualice la location donde este
   */
  dismiss(value) {
    this.modalCtrl.dismiss({ update : value } );
  }

}
