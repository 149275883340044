import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-url-content',
  templateUrl: './url-content.page.html',
  styleUrls: ['./url-content.page.scss'],
})
export class UrlContentPage implements OnInit {
  @Input() file: any;
  @Input() tipo: any;
  imageUrl:any='';
  

  constructor(
  private modalCtrl : ModalController
  
  ) { }

  ngOnInit() {

    //console.log(this.file, this.tipo);

    this.imageUrl = this.file;
    
  }

  /**
   *  @description cerrar el modal 
   *  @param value true o false para que al cerrar el modal se actualice la location donde este
   */
  dismiss(value) {
    this.modalCtrl.dismiss({ update : value } );
  }
}
