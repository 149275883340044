import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController, ModalController } from '@ionic/angular';
import { Util } from '../../../shared/util/util';
import { UrlContentPage } from '../url-content/url-content.page';
import { Router } from '@angular/router';
import { OtherService } from '../../../shared/services/other/other.service';
import { Platform } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { DocumentViewer, DocumentViewerOptions } from '@ionic-native/document-viewer/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.page.html',
  styleUrls: ['./popover.page.scss'],
})
export class PopoverPage implements OnInit {
  type = null;
  list=[];
  constructor(
    private navParams: NavParams, 
    private popoverController: PopoverController, 
    private util:Util,
    private modalController:ModalController,
    private router:Router,
    private os:OtherService,
    private platform: Platform,
    private iab: InAppBrowser,
    private file: File,
    private ft: FileTransfer, 
    private fileOpener: FileOpener,
    private document: DocumentViewer,
    ) { }


    deleteFile(id){
      //this.util.presentLoading();
      
      this.util.presentToast("Se esta procesando su solicitud, Espere!", "succes");
      if(id != 'null'){
        this.os.deleteFile(id).subscribe(
          res => {
            
            console.log(res);
            this.util.presentToast("Archivo Eliminado!", "succes");
            this.closePopover('update');
            //  this.util.dismissLoading();
          
          },
          err => {
            //this.util.dismissLoading();
            console.log(err);
            this.util.presentToast("Ha ocurrido un problema, Intentelo nuevamente", "alert");
            
          }
        );
        this.os.deleteFile(id);

      }
    }
  ngOnInit() {
    this.type = this.navParams.get('typefiles');
    let dataUser = JSON.parse(localStorage.getItem("userApi"));
    
    switch (this.type) {
      case 'poliza':
      
        if (dataUser.policy.files.policy != null) {
          let obj = {}
          dataUser.policy.files.policy.forEach((policy) => {
            if (this.util.ext(policy.url_file) == ".jpg" || this.util.ext(policy.url_file) == ".jpeg" || this.util.ext(policy.url_file) == ".png" || this.util.ext(policy.url_file) == ".gif") {
              //archivo adjunto es tipo de foto
              obj = {
                file:policy.url_file,
                type:'foto',
                id:policy.id
              }              
  
            } else if (this.util.ext(policy.url_file) == ".doc" || this.util.ext(policy.url_file) == ".pdf" || this.util.ext(policy.url_file) == ".docx") {
              //documento
              obj = {
                file:policy.url_file,
                type:'document',
                id:policy.id
              }
            } else if (this.util.ext(policy.url_file) == null) {
              //el usuario no ha adjuntado ningun archivo
              obj = {
                file:'null',
                type:'null',
                id:'null'
              }
            } else {
              // si no se reconoce el tipo de archivo
              obj = {
                file:policy.url_file,
                type:'',
                id:policy.id
              }
            }
            this.list.push(obj);
            obj = {}
           //console.log(policy);
          });
        }else{
          let obj = {
            file:'null',
            type:'null',
            id:'null'
          }
          this.list.push(obj);           
        }
         
        break;
      case 'carnet':
      if (dataUser.policy.files.carnet != null) {
        let obj = {}
        dataUser.policy.files.carnet.forEach((policy) => {
          if (this.util.ext(policy.url_file) == ".jpg" || this.util.ext(policy.url_file) == ".jpeg" || this.util.ext(policy.url_file) == ".png" || this.util.ext(policy.url_file) == ".gif") {
            //archivo adjunto es tipo de foto
            obj = {
              file:policy.url_file,
              type:'foto',
              id:policy.id
            }              

          } else if (this.util.ext(policy.url_file) == ".doc" || this.util.ext(policy.url_file) == ".pdf" || this.util.ext(policy.url_file) == ".docx") {
            //documento
            obj = {
              file:policy.url_file,
              type:'document',
              id:policy.id
            }
          } else if (this.util.ext(policy.url_file) == null) {
            //el usuario no ha adjuntado ningun archivo
            obj = {
              file:'null',
              type:'null',
              id:'null'
            }
          } else {
            // si no se reconoce el tipo de archivo
            obj = {
              file:policy.url_file,
              type:'',
              id:policy.id
            }
          }
          this.list.push(obj);
          obj = {}
         //console.log(policy);
        });
      }else{
        let obj = {
          file:'null',
          type:'null',
          id:'null'
        }
        this.list.push(obj);           
      }
        break;
      case 'redmedica':
      if (dataUser.policy.files.medical_red != null) {
        let obj = {}
        dataUser.policy.files.medical_red.forEach((policy) => {
          if (this.util.ext(policy.url_file) == ".jpg" || this.util.ext(policy.url_file) == ".jpeg" || this.util.ext(policy.url_file) == ".png" || this.util.ext(policy.url_file) == ".gif") {
            //archivo adjunto es tipo de foto
            obj = {
              file:policy.url_file,
              type:'foto',
              id:policy.id
            }              

          } else if (this.util.ext(policy.url_file) == ".doc" || this.util.ext(policy.url_file) == ".pdf" || this.util.ext(policy.url_file) == ".docx") {
            //documento
            obj = {
              file:policy.url_file,
              type:'document',
              id:policy.id
            }
          } else if (this.util.ext(policy.url_file) == null) {
            //el usuario no ha adjuntado ningun archivo
            obj = {
              file:'null',
              type:'null',
              id:'null'
            }
          } else {
            // si no se reconoce el tipo de archivo
            obj = {
              file:policy.url_file,
              type:'',
              id:policy.id
            }
          }
          this.list.push(obj);
          obj = {}
         //console.log(policy);
        });
      }else{
        let obj = {
          file:'null',
          type:'null',
          id:'null'
        }
        this.list.push(obj);           
      } 
        break;
      default:
        break;
    }

  }

  closePopover(value = "false") {
    this.popoverController.dismiss({ update : value });
  }



  /**
   * @description  cuando se da click a un boton que muestra las fotos de la poliza
   */
  openUrl(type, file){
        switch (type) {
          case 'foto':
            this.showUrlContent('foto',file);
            break;
          case 'document':            
            //window.open(file,'_system', 'location=yes');
            //this.downloadAndOpenPdf(file);
            this.downloadAndOpenPdf(file);
            //this.iab.create(this.dataPoliza.policy.url_file,'_system');
            break;
          case '':
            const browser = this.iab.create(file,'_system','location=yes');
            //window.open(file,'_system', 'location=yes');
            //const browser = this.iab.create(this.dataPoliza.policy.url_file);
            break;
          case 'null':
            //this.util.presentToast("Ingrese archivos de la póliza", "alert");
            //this.router.navigateByUrl('/home');
            this.closePopover('true');
            //open dialog to edit
            //this.editPolicy('edit');
            break;         
        } 
  }

  downloadAndOpenPdf(file) {
    if (this.platform.is('android')) {
      const browser = this.iab.create('https://docs.google.com/viewer?url='+file,'_system','location=yes');  
    }else{
      const browser = this.iab.create(file,'_system','location=yes');
    }
  }

  /*downloadAndOpenPdf(file) {
    let downloadUrl = 'https://devdactic.com/html/5-simple-hacks-LBT.pdf';
    let path = this.file.dataDirectory;
    const transfer = this.ft.create();
    if(this.platform.is('android') || this.platform.is('ios')){
      transfer.download(downloadUrl, path + 'myfile.pdf').then(entry => {
        let url = entry.toURL();
        if (this.platform.is('ios')) {
          this.document.viewDocument(url, 'application/pdf', {});
        } else {
          this.fileOpener.open(url, 'application/pdf')
            .then(() => console.log('File is opened'))
            .catch(
              e => {
                const browser = this.iab.create('https://docs.google.com/viewer?url='+file,'_system','location=yes');
                console.log('Error opening file', e);
                //open a app to pdf visor
                //this.market.search('.pdf');
              });
        }
      });
    }else{
      const browser = this.iab.create(file,'_system','location=yes');
    }
  }*/

  
  /**
    * @async
    * @description 
    * @param value Para saber el tipo de archivo que se mostrara en el modal
    * @param data posee el archivo que se desea procesar (foto, documento, etc...)
    */
   async showUrlContent(value,data) {
    let modal;
    //create modal
  
      modal = await this.modalController.create({
        component: UrlContentPage,
        componentProps: { tipo: value, file: data},
        cssClass: 'inset-modal'
      });    
    
    //Cuando se cierre el modal...
    modal.onWillDismiss().then(data => {
      
      if(data.data.update == "true"){
     
      
       
        
      }
      
    });
    return await modal.present();

  }

}
