import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { API } from './../../util/constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {

  constructor(private http:HttpClient) { }


  /**
   * 
   * @description Obtiene los datos de la poliza del usuario logueado 
   * @returns Observable<any> 
   */
  public getDataPolicy(): Observable<any>{
    let dataUser = JSON.parse(localStorage.getItem("userApi"));
    return this.http.get<any>(""+API+"/policy/"+dataUser.policy.id+"");
  }


  public updatePolicy(body): Observable<any>{
    let dataUser = JSON.parse(localStorage.getItem("userApi"));
    return this.http.put<any>(""+API+"/policy/"+dataUser.policy.id+"",body);
  }

  public setPolicy(body): Observable<any>{    
    return this.http.post<any>(""+API+"/policy" , body);
  }
}
