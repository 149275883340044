import { HttpClient } from '@angular/common/http';
import { API } from './../../util/constants';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MedicineService {
    medicines: any[];

    constructor(private http: HttpClient) {}

    /**
     *
     * @description Para consultar los tipos de presentaciones
     * @returns Observable<any>
     */
    public getPresentation(): Observable<any> {
        return this.http.get<any>('' + API + '/presentations/drugs/get');
    }

    /**
     *
     * @description Para consultar las cantidad
     * @returns Observable<any>
     */
    public geQuantities(): Observable<any> {
        return this.http.get<any>('' + API + '/presentations/drugs/get');
    }

    /**
     *
     * @description Para solicitar medicina
     * @returns Observable<any>
     */
    public solicitarMedicine(body): Observable<any> {
        return this.http.post<any>('' + API + '/medicationrequest', body);
    }

    /**
     *
     * @description Historial de solicitudes hechas de medicinas
     * @returns Observable<any>
     */
    public getHistorialMedicine(): Observable<any> {
        return this.http.get<any>('' + API + '/auth/requests');
    }

    /**
     *
     * @description obtener detalles de la solicitud de medicinas mediante id de la solicitud
     * @returns Observable<any>
     */
    public getMedicationRequest(id): Observable<any> {
        return this.http.get<any>('' + API + '/medicationrequest/' + id + '');
    }

    public getTypeRequest(): Observable<any> {
        return this.http.get<any>('' + API + '/type-requests');
    }

    public findMedicineByName(name: string) {
        if (this.medicines == undefined) {
            this.http
                .post(API + '/find/medicine', { name: '' })
                .subscribe((res: any) => {
                    this.medicines = res.medicines;
                    return this.medicines;
                });
        } else {
            const pattern = new RegExp(`^${name}`, 'i');
            return this.medicines.filter(medicine =>
                pattern.test(medicine.name)
            );
        }
    }
}
